import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './includes/Footer'
import Header from './includes/header'

function HomeLayout() {
  return (
    <div className='bg-light'>
        <div className=''>
            <Outlet />
        </div>
        <Footer />
    </div>
  )
}

export default HomeLayout