import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from '../components/layouts/appLayout';
import HomeLayout from '../components/layouts/homeLayout';
import { paths } from '../config/constant';

const SolutionAndServices = lazy(() => import('../pages/solotionAndServices'));
const AboutUs = lazy(() => import('../pages/aboutus'));
const ContactUs = lazy(() => import('../pages/contactus'));
const Insight = lazy(() => import('../pages/insight'));
const Career = lazy(() => import('../pages/career'));
const HomePage = lazy(() => import('../pages/home'));
const Mumbai = lazy(() => import('../pages/city/mumbai'));
const Dubai = lazy(() => import('../pages/city/dubai'));
const UnitedKingdom = lazy(() => import('../pages/city/unitedKingdom'));
const Labuan = lazy(() => import('../pages/city/labuan'));
const Nairobi = lazy(() => import('../pages/city/nairobi'));
const NotFound = lazy(() => import('../pages/notFound'));


function RouteApp() {
    return (<>
        <Routes>
            <Route path={paths.home} element={<HomeLayout />}>
                <Route path={paths.home} element={<Suspense fallback={""}><HomePage /></Suspense>} />
            </Route>

            <Route path={paths.home} element={<AppLayout />}>
                <Route path={paths.carrer} element={<Suspense fallback={""}><Career /></Suspense>} />
                <Route path={paths.insight} element={<Suspense fallback={""}><Insight /></Suspense>} />
                <Route path={paths.contactus} element={<Suspense fallback={""}><ContactUs /></Suspense>} />
                <Route path={paths.aboutus} element={<Suspense fallback={""}><AboutUs /></Suspense>} />
                <Route path={paths.solutionServices} element={<Suspense fallback={""}><SolutionAndServices /></Suspense>} />
                <Route path={paths.mumbai} element={<Suspense fallback={""}><Mumbai /></Suspense>} />
                <Route path={paths.dubai} element={<Suspense fallback={""}><Dubai /></Suspense>} />
                <Route path={paths.unitedKingdom} element={<Suspense fallback={""}><UnitedKingdom /></Suspense>} />
                <Route path={paths.labuan} element={<Suspense fallback={""}><Labuan /></Suspense>} />
                <Route path={paths.nairobi} element={<Suspense fallback={""}><Nairobi /></Suspense>} />

                <Route path={"*"} element={<Suspense fallback={""}><NotFound /></Suspense>}/>
            </Route>

        </Routes>
    </>);
}

export default RouteApp;