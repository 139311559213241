import React, { useState } from 'react'
import { Navigate, NavLink } from 'react-router-dom';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/dist/dropdown';
import logo from '../../../assets/images/logo.png';
import efac from '../../../assets/images/Efac_Application_icon.png';
import { Drawer, IconButton } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import { paths } from '../../../config/constant';
import { useNavigate } from 'react-router-dom';
import { HighlightOffRounded, LinkRounded } from '@mui/icons-material';

function HomeNavBar() {
    const navigate = useNavigate();
    const [_drawer, _setDrawer] = useState<any>(false);

    const handleClose = () => {
        _setDrawer(false)
    }

    return (
        <>
            <div>
                <div className='row align-items-center justify-content-between hNavbar bg-primary'>
                    <div className="col-2 text-center">
                        <IconButton onClick={() => _setDrawer(true)}><MenuIcon className='text-white fs-1' /></IconButton>
                    </div>
                    <div className='col-7 logoC' role="button" onClick={() => navigate(paths.home)}>
                        <img src={logo} className="hLogo" />
                    </div>
                    <div className='col-3 text-center dBlock'>
                        <a href="https://portal.reins-global.com" target="_blank"><span className='text-danger fs4' role="button"><LinkRounded style={{ color: "white", fontSize:"42px" }} /> RI FAC</span></a>
                    </div>

                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm m-0" style={{
                position: "absolute",
                top: "18%",
                zIndex: "100",
                left: "5%",
                right: "5%",
            }}>
            </nav>

            <Drawer anchor={'left'} open={_drawer} onClose={() => _setDrawer(false)} PaperProps={{ sx: { width: "300px", background:"#111A2D" } }} disableScrollLock={true}>
                <div className="p-1 mt-3 px-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className='ms-4' role="button" onClick={() => navigate(paths.home)}>
                            <img src={logo} style={{ height: "2.4rem" }} />
                        </div>
                        <IconButton className="p-0 m-0" sx={{ mx: '4px', color: "white" }} onClick={() => _setDrawer(false)}>
                            <HighlightOffRounded style={{fontSize:'32px'}} />
                        </IconButton>
                    </div>
                    <div className="mt-5 sidenav">
                        <NavLink className="fs-5 text-white text-decoration-none " to={paths.home} onClick={handleClose}><div className="px-4 navItem mt-4" role="button">Home</div></NavLink>
                        <NavLink className="fs-5 text-white text-decoration-none" to={paths.aboutus} onClick={handleClose}><div className="px-4 navItem mt-4" role="button">About Us</div></NavLink>
                        <NavLink className="fs-5 text-white text-decoration-none" to={paths.solutionServices} onClick={handleClose}><div className="px-4 navItem mt-4" role="button">Services</div></NavLink>
                        <NavLink className="fs-5 text-white text-decoration-none" to={paths.insight} onClick={handleClose}><div className="px-4 navItem mt-4" role="button">Insights</div></NavLink>
                        <NavLink className="fs-5 text-white text-decoration-none" to={paths.carrer} onClick={handleClose}><div className="px-4 navItem mt-4" role="button">Careers</div></NavLink>
                        {/* <NavLink className="fs-5 text-white text-decoration-none" to={paths.clientele} onClick={handleClose}><div className="px-4 navItem mt-4" role="button">Clientele</div></NavLink> */}
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default HomeNavBar;
