import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './includes/Footer'
import HomeNavBar from './includes/HomeNavBar'

function AppLayout() {
  return (
    <div>
        <HomeNavBar />
        <div>
            <Outlet />
        </div>
        <Footer />
    </div>
  )
}

export default AppLayout